import styled from 'styled-components';
import Image from 'next/image';
import LoginWithTelegramButton from '@/entities/login-with-telegram-button';
import DownloadAppButton from '@/entities/download-app-button';
import { Container } from '@/shared/ui';

export const VideoPlaceholderImage = styled(Image)``;

export const PlayVideoMobileButton = styled.div`
  display: flex;
  height: 72px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.4px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const VideoPlaceholderWrapper = styled.div`
  position: relative;

  div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    color: #fff;
    font-size: 13.091px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.455px;
    letter-spacing: 0.109px;
    text-transform: uppercase;

    svg {
      cursor: pointer;
    }
  }

  @media (max-width: 1440px) {
    div {
      width: 100%;
    }
    img {
      width: 250px;
      height: 500px;
    }
  }
`;

export const CloseMobilePlayerButton = styled.button`
  display: none;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 1px;
    border: none;
    color: black;
    border-radius: 100vmax;
    position: absolute;
    z-index: 3001;
    left: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c1c1c1;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 300px;
  height: 600px;
  padding: 15px;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  iframe {
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1440px) {
    width: 250px;
    height: 500px;
  }

  @media (max-width: 900px) {
    position: fixed;
    z-index: 3000;
    width: 100%;
    height: 100%;
    background: #000;
    left: 0;
    top: 0;
    padding: 0;

    > img {
      display: none;
    }

    iframe {
      border-radius: unset;
    }
  }
`;
export const UnblockVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
  }
`;

export const VerticalDivider = styled.div`
  width: 1px;
  background: rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    display: none;
  }
`;

export const UnblockStepText = styled.p`
  color: #ffffff;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
  margin: 5px 0 0;
  padding: 0;
  grid-column: 2;
  grid-row-start: 2;
  grid-row-end: 2;
`;

export const UnblockStepTitle = styled.p`
  position: relative;
  margin: 0;
  font-style: normal;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
  color: white;
  grid-column: 2;
  grid-row-start: 1;
  grid-row-end: 1;
`;

export const UnblockStep = styled.div`
  counter-increment: step;
  position: relative;
  padding: 15px 15px 15px 55px;
  display: flex;
  align-items: center;
  gap: 25px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  background: radial-gradient(88.08% 100% at 50.05% 100%, #8916de 0%, #2d0252 70%, rgba(30, 30, 30, 0) 100%);
  background-clip: padding-box;

  > div {
    flex: 1;
  }

  img {
    width: 220px;
    height: auto;
  }

  &:nth-child(odd) {
    > span {
      transform: rotate(-5deg);
    }
  }
  &:nth-child(even) {
    > span {
      transform: rotate(5deg);
    }
  }

  > span {
    padding: 0 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.2px;
    color: white;
    max-height: 42px;
    height: 100%;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background: linear-gradient(180.78deg, #000000 1.59%, #842ac4 99.33%);
    background-clip: padding-box;
    left: -10px;
    top: -10px;
  }

  @media (max-width: 1280px) {
    padding: 15px 15px 15px 40px;
  }

  @media (max-width: 630px) {
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    text-align: center;

    ${UnblockStepTitle} {
      margin: 0 auto;
    }

    img {
      width: calc(100% - 60px);
      height: auto;
    }

    > span {
      transform: translate(-50%, -50%) rotate(0) !important;
      left: 50%;
      top: 0;
    }

    > div {
      width: 100% !important;
      height: 100% !important;
      max-height: unset !important;
      max-width: unset !important;
    }

    ${UnblockStepTitle} {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.2px;
    }

    ${UnblockStepText} {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.1px;
    }
  }
`;

export const UnblockStepsList = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 630px) {
    margin-top: 50px;
    gap: 45px;
  }
`;

export const MobileViewUnblockVideoWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #ffffffcc;
`;

export const Wrapper = styled(Container)`
  padding: 40px 16px 0 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;

  > div {
    h1 {
      color: white;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -1px;
      margin: 0;
      padding: 0;
    }
    > p {
      margin: 0;
      padding: 0;
      color: rgba(255, 255, 255, 0.8);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.2px;
    }
  }

  @media (max-width: 1440px) {
    > div {
      h1 {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.7px;
      }
      > p {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.2px;
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
    > div {
      h1 {
        font-size: 32px;
      }
    }
  }

  @media (max-width: 450px) {
    > div {
      h1 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
  }
`;

export const StyledLoginWithTelegramButton = styled(LoginWithTelegramButton)`
  margin-top: 10px;

  @media (max-width: 630px) {
    margin: 10px auto 0;
  }
`;

export const DownloadAppButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  gap: 8px;
  margin-top: 10px;

  @media (max-width: 630px) {
    display: flex;
    flex-direction: column;

    > * {
      flex-grow: 1;
    }
  }
`;

export const StyledDownloadAppButton = styled(DownloadAppButton)`
  border-radius: 20px;
  height: 40px;
  min-width: auto;
  width: auto;
`;
