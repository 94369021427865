import React from 'react';
import { useLocale, useTranslations } from 'next-intl';
import Image from 'next/image';
import phone from '@/public/staticfiles/unblock/video-wrapper-min.png';
import ph from '@/public/staticfiles/unblock/video-placeholder-min.png';
import { useAnalyticsContext } from '@/processes/analytics';
import { PlayButtonIcon } from '@/shared/ui/Icon/svg';
import YoutubeIcon from '@/shared/ui/Icon/svg/youtube.svg';

import { UNBLOCK_INSTRUCTIONS_BY_LANGUAGE } from '../model/constants';
import * as SC from './styled';

function UnblockVideoPlayer({ isMobile }: { isMobile: boolean }) {
  const t = useTranslations();
  const locale = useLocale();
  const { sendSafeGtagEvent, sendSafeYMEvent } = useAnalyticsContext();
  const [isPlaying, setIsPlaying] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isMobile && isPlaying) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'hidden auto';
      }
    }
  }, [isMobile, isPlaying]);

  const handlePlayVideo = () => {
    setIsPlaying(true);
    sendSafeGtagEvent('show_tutorial');
    sendSafeYMEvent('tutorial_show');
  };

  return (
    <SC.UnblockVideoWrapper>
      {isPlaying ? (
        <SC.VideoContainer>
          <Image src={phone} width={300} height={600} alt="" />
          {isMobile && <SC.CloseMobilePlayerButton onClick={() => setIsPlaying(false)}>x</SC.CloseMobilePlayerButton>}
          <iframe
            src={`${
              UNBLOCK_INSTRUCTIONS_BY_LANGUAGE[locale as keyof typeof UNBLOCK_INSTRUCTIONS_BY_LANGUAGE]
            }?autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </SC.VideoContainer>
      ) : isMobile ? (
        <SC.PlayVideoMobileButton onClick={handlePlayVideo}>
          <YoutubeIcon />
          {t('login.instructions.play_tutorial')}
        </SC.PlayVideoMobileButton>
      ) : (
        <SC.VideoPlaceholderWrapper>
          <div>
            <PlayButtonIcon onClick={handlePlayVideo} />
            {t('login.instructions.play_tutorial')}
          </div>
          <SC.VideoPlaceholderImage src={ph} alt="" width={300} height={600} />
        </SC.VideoPlaceholderWrapper>
      )}
    </SC.UnblockVideoWrapper>
  );
}

export default UnblockVideoPlayer;
