'use client';

import React from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useUserInfoContext } from '@/processes/auth';
import { useAnalyticsContext } from '@/processes/analytics';
import api from '@/shared/api';
import { TgWidgetUser } from '@/shared/lib/types';
import { useRouter } from '@/shared/lib/hooks';

import initTgWidget from '../../lib/init-tg-widget';
import * as SC from './styled';

function LoginWithTelegramButton({ className }: LoginWithTelegramButtonProps) {
  const t = useTranslations();

  const router = useRouter();
  const searchParams = useSearchParams();

  const { user, isFetching, fetchUser } = useUserInfoContext();
  const { sendSafeGtagEvent, sendSafeYMEvent } = useAnalyticsContext();

  const handleLoginButtonClick = () => {
    const tgInitData = window.Telegram?.WebApp?.initData || '';

    if (tgInitData) {
      api.authService.auth
        .fetchPostLoginWithMiniApp(tgInitData)
        .then(fetchUser)
        .then(() => {
          toast.success(t('login.auth_success'));
          router.push('/settings');
        })
        .catch((e) => {
          toast.error(t('login.auth_error'));
          console.log(e);
        });
    } else {
      (window as any).Telegram.Login.auth(
        // todo: Move to env
        { bot_id: process.env.NEXT_PUBLIC_BOT_ID, request_access: true },
        (data: TgWidgetUser) => {
          if (!data) return;
          return api.authService.auth
            .fetchPostLogin(data)
            .then(fetchUser)
            .then(() => {
              toast.success(t('login.auth_success'));
              router.push('/settings');
            })
            .catch((e) => {
              toast.error(t('login.auth_error'));
              console.log(e);
            });
        },
      );
    }
  };

  const campaignUTM = searchParams.get('campaign');

  React.useEffect(() => {
    if (campaignUTM) {
      localStorage.setItem('campaign_utm', campaignUTM);
    }
  }, [campaignUTM]);

  React.useEffect(() => {
    sendSafeGtagEvent('show_login');
    sendSafeYMEvent('login_show');

    if (!document.getElementById('initTgWidgetID')) {
      initTgWidget();
    }
  }, []);

  React.useEffect(() => {
    if (!isFetching && user) {
      router.push('/settings');
    }
  }, [isFetching, user]);

  return (
    <SC.RootButton
      className={className}
      id="tgLogin"
      onClick={handleLoginButtonClick}
      fullWidth
      variant="third"
      label={
        <SC.ButtonLabelWrapper>
          <SC.StyledPlaneIcon />
          {t('login.unblock.login_with_tg')}
        </SC.ButtonLabelWrapper>
      }
    />
  );
}

interface LoginWithTelegramButtonProps {
  className?: string;
}

export default LoginWithTelegramButton;
