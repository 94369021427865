function initTgWidget() {
  if (
    typeof window === 'undefined' ||
    // todo: Move to env
    document.querySelector('script[data-telegram-login="Nicegram_bot"]')
  ) {
    return;
  }

  const newScript = document.createElement('script');
  newScript.src = 'https://telegram.org/js/telegram-widget.js?22';
  newScript.setAttribute('data-telegram-login', process.env.NEXT_PUBLIC_BOT_USERNAME ?? 'Nicegram_bot');
  newScript.setAttribute('data-size', 'large');
  newScript.setAttribute('data-request-access', 'write');
  newScript.setAttribute('data-auth-url', process.env.NEXT_PUBLIC_DATA_AUTH_URL ?? 'https://my.nicegram.app');
  newScript.setAttribute('id', 'initTgWidgetID');

  document.getElementById('tgLogin')?.appendChild(newScript);
}

export default initTgWidget;
