'use client';

import React from 'react';
import Image from 'next/image';
import { useTranslations, useFormatter, useLocale, createFormatter } from 'next-intl';
import lottieStep1 from '@/public/staticfiles/unblock/lottie/anim_1.json';
import lottieStep2 from '@/public/staticfiles/unblock/lottie/anim_2.json';
import lottieStep3 from '@/public/staticfiles/unblock/lottie/anim_3.json';
import lottieStep4 from '@/public/staticfiles/unblock/lottie/anim_4.json';
import previewStep1 from '@/public/staticfiles/unblock/lottie/anim_1_fallback.png';
import previewStep2 from '@/public/staticfiles/unblock/lottie/anim_2_fallback.png';
import previewStep3 from '@/public/staticfiles/unblock/lottie/anim_3_fallback.png';
import previewStep4 from '@/public/staticfiles/unblock/lottie/anim_4_fallback.png';
import { useDeviceInfoContext } from '@/processes/device-info';
import { useMediaQuery } from '@/shared/lib/hooks';
import { LazyLottie } from '@/shared/ui/LazyLottie/LazyLottie';

import UnblockVideoPlayer from './UnblockVideoPlayer';
import * as SC from './styled';

function UnblockGuide() {
  const t = useTranslations();
  const locale = useLocale();

  const { isMobile } = useDeviceInfoContext();
  const isSmallScreen = useMediaQuery('(max-width: 900px)');

  const isMobileView = isMobile || isSmallScreen;

  const format = React.useMemo(() => createFormatter({ locale: locale === 'ar' ? 'ar-EG' : locale }), [locale]);

  return (
    <SC.Wrapper>
      <div>
        <h1>{t('login.instructions.instructions_title')}</h1>
        <p>{t('login.instructions.instructions_subtitle')}</p>

        <SC.UnblockStepsList>
          <SC.UnblockStep>
            <span>
              {t('login.instructions.step_number', {
                step: format.number(1),
              })}
            </span>
            <LazyLottie
              animationData={lottieStep1}
              fallback={<Image src={previewStep1} alt="" width={220} height={110} />}
              loop
              id="unblock_lottie_1"
              width={220}
              height={110}
            />
            <div>
              <SC.UnblockStepTitle>{t('login.instructions.instructions_step_1_title')}</SC.UnblockStepTitle>
              <SC.UnblockStepText>{t('login.instructions.instructions_step_1_text')}</SC.UnblockStepText>

              <SC.DownloadAppButtonsContainer>
                <SC.StyledDownloadAppButton type="appstore" />
                <SC.StyledDownloadAppButton type="gplay" />
                <SC.StyledDownloadAppButton type="desktopDmg" />
              </SC.DownloadAppButtonsContainer>
            </div>
          </SC.UnblockStep>
          <SC.UnblockStep>
            <span>{t('login.instructions.step_number', { step: format.number(2) })}</span>
            <LazyLottie
              animationData={lottieStep2}
              fallback={<Image src={previewStep2} alt="" width={220} height={110} />}
              loop
              id="unblock_lottie_2"
              width={220}
              height={110}
            />
            <div>
              <SC.UnblockStepTitle>{t('login.instructions.instructions_step_2_title')}</SC.UnblockStepTitle>
              <SC.UnblockStepText>{t('login.instructions.instructions_step_2_text')}</SC.UnblockStepText>

              <SC.StyledLoginWithTelegramButton />
            </div>
          </SC.UnblockStep>
          <SC.UnblockStep>
            <span>{t('login.instructions.step_number', { step: format.number(3) })}</span>
            <LazyLottie
              animationData={lottieStep3}
              fallback={<Image src={previewStep3} alt="" width={220} height={110} />}
              loop
              id="unblock_lottie_3"
              width={220}
              height={110}
            />
            <div>
              <SC.UnblockStepTitle>{t('login.instructions.instructions_step_3_title')}</SC.UnblockStepTitle>
              <SC.UnblockStepText>{t('login.instructions.instructions_step_3_text')}</SC.UnblockStepText>
            </div>
          </SC.UnblockStep>
          <SC.UnblockStep>
            <span>{t('login.instructions.step_number', { step: format.number(4) })}</span>
            <LazyLottie
              animationData={lottieStep4}
              fallback={<Image src={previewStep4} alt="" width={220} height={110} />}
              loop
              id="unblock_lottie_4"
              width={220}
              height={110}
            />
            <div>
              <SC.UnblockStepTitle>{t('login.instructions.instructions_step_4_title')}</SC.UnblockStepTitle>
              <SC.UnblockStepText>{t('login.instructions.instructions_step_4_text')}</SC.UnblockStepText>
            </div>
          </SC.UnblockStep>
        </SC.UnblockStepsList>
      </div>
      <SC.VerticalDivider />

      {isMobileView ? (
        <SC.MobileViewUnblockVideoWrapper>
          <span>{t('login.instructions.or_manual_instruction')}</span>
          <UnblockVideoPlayer isMobile />
        </SC.MobileViewUnblockVideoWrapper>
      ) : (
        <UnblockVideoPlayer isMobile={false} />
      )}
    </SC.Wrapper>
  );
}

export default UnblockGuide;
