'use client';

import React from 'react';
import { useAnalyticsContext } from '@/processes/analytics';
import { default as DesktopDmgIcon } from '@/shared/ui/Icon/svg/desktop-dmg.svg';
import { default as GplayIcon } from '@/shared/ui/Icon/svg/gplay.svg';
import { default as AppstoreIcon } from '@/shared/ui/Icon/svg/appstore.svg';

import * as SC from './styled';

function DownloadAppButton({ type, className }: DownloadAppButtonProps) {
  const { sendSafeYMEvent, sendSafeGtagEvent } = useAnalyticsContext();

  const buttonProps = React.useMemo<{
    href: string;
    label: React.ReactNode;
    onClick?: () => void;
  }>(() => {
    switch (type) {
      case 'gplay':
        return {
          href: 'https://play.google.com/store/apps/details?id=app.nicegram&utm_source=nicegram.app&utm_medium=main&utm_campaign=web&pli=1',
          label: <GplayIcon />,
          onClick: () => {
            sendSafeGtagEvent('gplay_click');
            sendSafeYMEvent('app_store_click');
          },
        };
      case 'appstore':
        return {
          href: 'https://apps.apple.com/app/apple-store/id1608870673?pt=119567154&ct=web3_v1&mt=8',
          label: <AppstoreIcon />,
          onClick: () => {
            sendSafeGtagEvent('appstore_click');
            sendSafeYMEvent('app_store_click');
          },
        };
      case 'desktopDmg':
        return {
          href: 'https://static.nicegram.app/desktop-versions/Nicegram%20Desktop.dmg',
          label: <DesktopDmgIcon />,
          onClick: () => {
            sendSafeGtagEvent('desktop_dmg_click');
            sendSafeYMEvent('desktop_dmg_click');
          },
        };
    }
  }, [sendSafeGtagEvent, sendSafeYMEvent, type]);

  return (
    <SC.Root
      className={className}
      forwardedAs="a"
      target="_blank"
      rel="noreferrer nofollow"
      variant="third"
      {...buttonProps}
    />
  );
}

interface DownloadAppButtonProps {
  className?: string;
  type: 'gplay' | 'appstore' | 'desktopDmg';
}

export default DownloadAppButton;
