'use client';

import styled from 'styled-components';
import { Button } from '@/shared/ui';
import { PlaneIcon } from '@/shared/ui/Icon/svg';

export const RootButton = styled(Button)`
  max-width: 300px;
  position: relative;
  padding: 14px 40px;
  line-height: 1;
  height: auto;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
  }
`;

export const ButtonLabelWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const StyledPlaneIcon = styled(PlaneIcon)`
  position: absolute;
  left: 16px;

  * {
    fill: #000000;
  }
`;
